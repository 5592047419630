import {Url} from '@wendig/lib'

const setHashParams = (params = {}) => {
  const url = Url.current()
  url.setHashParams(params)
  url.apply()
}

const getHashParam = (param) => {
  const url = Url.current()
  return url.hashParams()[param]
}

const setupHotspotContainers = () => {
  const containers = document.querySelectorAll('.hotspot-container')
  for (const container of containers) {
    const width = container.clientWidth
    const height = container.clientHeight

    container.addEventListener('mousemove', event => {
      if (event.ctrlKey && event.shiftKey) {
        const x = (event.layerX / width).toFixed(2) * 100
        const y = (event.layerY / height).toFixed(2) * 100

        console.log(x, y)
      }
    })
  }
}

export {
  getHashParam,
  setHashParams,
  setupHotspotContainers
}