<team-grid class="row">
  <div
    each={id in randomizedIds()}
    is="team-cell"
    team-id={id}
    name={state.data[id].name}
    role={state.data[id].role}
    website={state.data[id].website}
    description={state.data[id].description}
    elevate="img"
  />

  <script>
    import {util} from '@wendig/lib'

    export default class {
      onBeforeMount(props, state) {
        this.state = {data: {}}

        fetch(`/data/${props.dataSet}.json`).then(r => r.json()).then(data => {
          this.update({data})
        })
      }

      randomizedIds() {
        const keys = Object.keys(this.state.data)
        return util.shuffle(keys)
      }
    }
  </script>
</team-grid>
