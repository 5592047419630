<raw>
  <script>
    export default class {
      onBeforeMount() {
        this.state = {old: null}
      }
      
      setInnerHTML() {
        if (this.state.old != this.props.html) {
          this.root.innerHTML = this.props.html
          this.state.old = this.props.html
        }
      }

      onMounted() {
        this.setInnerHTML()
      }
      
      onUpdated() {
        this.setInnerHTML()
      }
    }
  </script>
</raw>
