<parallax>
  <script>
    import simpleParallax from 'simple-parallax-js'

    export default class {
      onMounted(props, state) {
        state.parallax = new simpleParallax(this.root, {
          delay: 0.6,
          scale: parseFloat(props.scale || '1.3'),
          orientation: props.orientation || 'up'
        })
      }
    }
  </script>
</parallax>