<vimeo>
  <div class="ratio ratio-{props.ratio || '16x9'}">
    <div
      if={!hasConsent()}
      class="dummy d-flex flex-column justify-content-center p-4"
    >
      <div class="text text-center">
        Showing content from vimeo.com on our pages may transmit your personal
        data to vimeo.com. By clicking the button below, you consent to this
        transmission.
      </div>
      <a
        onclick={event => consent(event)}
        class="btn btn-info rounded-5 text-white"
      >
        <svg is="icon" name="arrow-right" class="me-2" />
        Load content
      </a>
    </div>
    <div if={hasConsent()} class="ratio ratio-{props.ratio || '16x9'}">
      <iframe
        src={iframeSrc()}
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  </div>

  <figcaption>
    <raw html={state.caption} />
  </figcaption>

  <script>
    import {Url} from '@wendig/lib'

    export default class {
      onBeforeMount() {
        this.state = {
          caption: this.root.innerHTML,
          autoplay: 0
        }

        this.root.innerHTML = ''

        this.on('consent-change', event => this.update())
      }

      iframeSrc() {
        const url = Url.parse(`https://player.vimeo.com/video/${this.props.dataId}`)
        url.updateParams({
          badge: 0,
          autopause: 0,
          quality_selector: 1,
          progress_bar: 1,
          player_id: 0,
          app_id: 58479,
          title: 0,
          byline: 0,
          portrait: 0,
          autoplay: this.state.autoplay ? 1 : null,
          loop: 1
        })

        return url.url()
      }

      consent(event) {
        event.preventDefault()

        sessionStorage.setItem('vimeo-consent', true)
        this.state.autoplay = 1
        this.bus.emit('consent-change')
      }

      hasConsent() {
        return sessionStorage.getItem('vimeo-consent')
      }
    }
  </script>
</vimeo>
