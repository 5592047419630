<pretty-loading class="{state.loaded ? 'loaded' : ''}">
  <fa-slot />
  <loading-indicator />

  <script>
    export default class {
      constructor() {
        this.done = this.done.bind(this)
      }

      onBeforeMount(props, state) {
        state.children = []
        state.loaded = false

        for (const node of this.root.childNodes) {
          state.children.push(node)
        }
      }

      onMounted(props, state) {
        state.waitCounter = 0

        const target = this.root.querySelector('fa-slot')
        for (const node of this.state.children) {
          if (node.tagName == 'FA-SLOT') continue;
          if (node.tagName == 'LOADING-INDICATOR') continue;

          target.append(node)
        }

        for (const video of this.root.querySelectorAll('video')) {
          state.waitCounter += 1
          video.addEventListener('play', this.done)
        }

        this.update()
      }

      onBeforeUpdate(props, state) {
        state.loaded = (state.waitCounter === 0)
      }

      done() {
        this.state.waitCounter -= 1
        this.update()
      }
    }
  </script>
</pretty-loading>
