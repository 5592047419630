<widget>
  <slot />

  <script>
    export default class {
      onMounted() {
        this.showCode()
      }

      showCode() {
        const render = this.root.querySelector('.render')
        const container = render.parentElement

        const header = document.createElement('h3')
        header.textContent = 'Code'
        container.append(header)

        const code = document.createElement('pre')
        const content = render.innerHTML.replaceAll(/\n {6}/g, "\n")
        code.append(content)
        container.append(code)
      }
    }
  </script>
</widget>