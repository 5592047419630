<auto-image src={src()}>

  <script>
    export default class {
      onMounted() {
        this.observer = new ResizeObserver(event => this.recalculate())
        this.observer.observe(this.root);
      }

      recalculate() {
        this.update()
      }

      src() {
        const size = (this.root.width > 768 ? 1920 : 768)
        return `/assets/images/auto/${this.props.name}.${size}.jpg`
      }
    }
  </script>
</auto-image>
