import * as riot from 'riot'

import {RiotPlugins, BusRiotPlugin, bus} from '@wendig/lib'

import Icon from '../components/icon.riot'
import Raw from '../components/raw.riot'
import Vimeo from '../components/vimeo.riot'
import Youtube from '../components/youtube.riot'

riot.install(BusRiotPlugin)
riot.install(RiotPlugins.setTitle)
riot.install(RiotPlugins.i18n)

riot.register('icon', Icon)
riot.register('raw', Raw)
riot.register('vimeo', Vimeo)
riot.register('youtube', Youtube)

export {riot, bus}
