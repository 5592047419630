<youtube>
  <div class="ratio ratio-{props.ratio || '16x9'}">
    <div
      if={!hasConsent()}
      class="dummy d-flex flex-column justify-content-center p-4"
    >
      <div class="text text-center">
        Showing content from youtube.com on our pages may transmit your personal
        data to youtube.com. By clicking the button below, you consent to this
        transmission.
      </div>
      <a
        onclick={event => consent(event)}
        class="btn btn-info rounded-5 text-white"
      >
        <svg is="icon" name="arrow-right" class="me-2" />
        Load content
      </a>
    </div>
    <iframe
      if={hasConsent()}
      class="w-100 h-100"
      src="https://www.youtube-nocookie.com/embed/{props.dataId}?autoplay={state.autoplay}"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
    ></iframe>
  </div>

  <figcaption>
    <raw html={state.caption} />
  </figcaption>

  <script>
    export default class {
      onBeforeMount() {
        this.state = {
          caption: this.root.innerHTML,
          autoplay: 0
        }

        this.root.innerHTML = ''

        this.on('consent-change', event => this.update())
      }

      consent(event) {
        event.preventDefault()

        sessionStorage.setItem('youtube-consent', true)
        this.state.autoplay = 1
        this.bus.emit('consent-change')
      }

      hasConsent() {
        return sessionStorage.getItem('youtube-consent')
      }
    }
  </script>
</youtube>
