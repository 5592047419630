<eye-catcher class="{active() ? 'active' : ''}">
  <a class="content" href={props.href}>
    Trailer<br />
    now<br />
    online!
  </a>

  <script>
    export default class {
      constructor() {
        this.updateWrapper = this.updateWrapper.bind(this)
      }

      onMounted() {
        window.addEventListener('scroll', this.updateWrapper)
      }

      updateWrapper() {
        this.update()
      }

      active() {
        const element = document.querySelector(this.props.selector)
        const eTop = element.getBoundingClientRect().top

        return window.innerHeight / 2 > eTop
      }
    }
  </script>
</eye-catcher>