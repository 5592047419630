<hotspot onclick={event => onClick(event)}>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path class="a" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
    <path class="b" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
  </svg>

  <div class="body p-3 {state.active && 'active'}">
    {state.body}
  </div>

  <script>
    export default class {
      onBeforeMount(props, state) {
        state.active = false
        state.body = this.root.innerHTML
        this.root.innerHTML = ''
      }

      onMounted() {
        this.setPosition()
      }

      onUpdated() {
        this.setPosition()
      }

      setPosition() {
        const [x, y] = this.props.position.split(',')

        this.root.style.left = `${x}%`
        this.root.style.top = `${y}%`
      }

      onClick(event) {
        event.preventDefault()

        this.update({active: !this.state.active})
      }
    }
  </script>
</hotspot>
