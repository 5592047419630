<team-cell class="col-12 col-md-6 col-lg-4 mb-4 mb-md-5 position-relative">
  <div class="ratio ratio-3x2 mb-2">
    <div>
      <img src="/assets/images/team/{props.teamId}.jpg" />
    </div>
  </div>
  <div class="name">{props.name}</div>
  <div class="role">{props.role}</div>

  <a
    class="stretched-link"
    onclick={event => toggle(event)}
  />

  <div class="description collapse">
    <raw html={description()} class="d-block my-4" />

    <div class="d-flex justify-content-center">
      <a
        class="btn btn-info rounded-5 text-white"
        href={props.website}
        target="_blank"
      >
        <svg is="icon" name="arrow-right" />
        Website
      </a>
    </div>
  </div>

  <div
    class="detail transition-toggle-width {state.sideClass}"
    ontransitionend={afterTransition}
  >
    <div class="w-md-50 p-md-5">
      <div class="d-none d-md-block">
        <div class="name">{props.name}</div>
        <div class="role mb-3">{props.role}</div>
      </div>

      <div class="description my-4">
        <raw html={description()} />
      </div>

      <a
        class="btn btn-info rounded-5 text-white"
        href={props.website}
        target="_blank"
      >
        <svg is="icon" name="arrow-right" class="me-2" />
        Website
      </a>
    </div>
  </div>

  <hr class="d-md-none w-50 mx-auto my-5" />

  <script>
    import {Collapse} from 'bootstrap'
    import {marked} from 'marked'

    import {riot, bus} from '../lib/riot'

    import Youtube from './youtube.riot'

    import {getHashParam, setHashParams} from '../lib/util'

    export default class {
      constructor() {
        this.toggle = this.toggle.bind(this)
        this.activate = this.activate.bind(this)
        this.deactivate = this.deactivate.bind(this)
        this.afterTransition = this.afterTransition.bind(this)
        this.updateSide = this.updateSide.bind(this)
      }

      onBeforeMount() {
        let backdrop = document.querySelector('body > .backdrop')
        if (!backdrop) {
          const body = document.documentElement.querySelector('body')
          backdrop = document.createElement('div')
          backdrop.classList.add('backdrop', 'transition-toggle-height')
          body.append(backdrop)
        }

        backdrop.addEventListener('click', this.deactivate)

        this.state = {
          backdrop
        }

        window.addEventListener('resize', this.updateSide)
        window.addEventListener('resize', this.deactivate)
        this.on('team-deactivate-all', this.deactivate)
      }

      onMounted() {
        this.updateSide()
        this.mountComponents()

        if (getHashParam('member') == this.props.teamId) {
          this.activate()
          this.root.querySelector('.detail').scrollIntoView()
        }
      }

      mountComponents() {
        for (const raw of this.root.querySelectorAll('raw')) {
          for (const e of raw.querySelectorAll('[is]')) {
            const r = riot.mount(e)
          }
        }
      }

      updateSide() {
        const selector = this.props.elevate
        const elevate = this.root.querySelector(selector)
        const rect = elevate.getBoundingClientRect()

        if (rect.left > window.innerWidth / 2) {
          this.update({sideClass: 'right'})
        } else {
          this.update({sideClass: ''})
        }
      }

      onUnmounted() {
        this.state.backdrop.removeEventListener('click', this.deactivate)
      }

      toggle() {
        if (this.state.active) {
          this.deactivate()
        } else {
          this.activate()
        }
      }

      activate() {
        this.state.backdrop.classList.add('active')
        const selector = this.props.elevate
        const elevate = this.root.querySelector(selector)
        elevate.classList.add('elevate')

        const detail = this.root.querySelector('.detail')
        detail.classList.add('active')

        const ce = this.root.querySelector('.collapse')
        const collapsible = Collapse.getOrCreateInstance(ce)
        collapsible.show()

        setHashParams({member: this.props.teamId})
        this.update({active: true})
      }

      deactivate() {
        this.state.backdrop.classList.remove('active')

        const detail = this.root.querySelector('.detail')
        detail.classList.remove('active')

        const ce = this.root.querySelector('.collapse')
        const collapsible = Collapse.getOrCreateInstance(ce)
        collapsible.hide()

        setHashParams({})
        this.update({active: false})
      }

      afterTransition() {
        const detail = this.root.querySelector('.detail')
        if (detail.classList.contains('active')) return;

        const selector = this.props.elevate
        const elevate = this.root.querySelector(selector)
        elevate.classList.remove('elevate')
      }

      description() {
        const md = this.props.description || ''
        const html = marked(md, {headerIds: false, mangle: false})

        return html
      }
    }
  </script>
</team-cell>