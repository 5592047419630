import * as Popper from "@popperjs/core"
import 'bootstrap'

import {VwUnit} from '@wendig/lib'

import {riot, bus} from './lib/riot'
import {setupHotspotContainers} from './lib/util'

import AutoImage from './components/auto_image.riot'
import Blockquote from './components/blockquote.riot'
import EyeCatcher from './components/eye_catcher.riot'
import Hotspot from './components/hotspot.riot'
import LoadingIndicator from './components/loading_indicator.riot'
import Parallax from './components/parallax.riot'
import PrettyLoading from './components/pretty_loading.riot'
import TeamCell from './components/team_cell.riot'
import TeamGrid from './components/team_grid.riot'
import Widget from './components/widget.riot'

riot.register('auto-image', AutoImage)
riot.register('fa-blockquote', Blockquote)
riot.register('eye-catcher', EyeCatcher)
riot.register('hotspot', Hotspot)
riot.register('loading-indicator', LoadingIndicator)
riot.register('parallax', Parallax)
riot.register('pretty-loading', PrettyLoading)
riot.register('team-cell', TeamCell)
riot.register('team-grid', TeamGrid)
riot.register('widget', Widget)

const vw = new VwUnit()
vw.setup()

setupHotspotContainers()

window.bus = bus

riot.mount('[is]')
